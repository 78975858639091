import Webview from "pages/webview";
import { useEffect } from "react";
import ReactGA from 'react-ga4';


function App() {
  useEffect(() => {
    // console.log('GTM', process.env.REACT_APP_GTM_ID)
    // if (process.env.REACT_APP_GTM_ID)
    //   TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

    console.log('GA', process.env.REACT_APP_GOOGLE_ANALYTIC_ID)
    if (process.env.REACT_APP_GOOGLE_ANALYTIC_ID)
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_ID);
  }, []);
  return (
    <div >
      <Webview/>
    </div>
  );
}

export default App;
