import "./index.scss"
import Advise from "./component/advise";
import Massaging from "./component/massaging";
import { useEffect, useState } from "react";
import axios from 'axios'
import nullIcon from "./image/image 18.png"
import { Modal } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ReactGA from 'react-ga4';
import Hairstyling from "./component/hairstyling";

export default function Webview() {
    const [listWaitSupporter, setListWaitSupporter] = useState([])
    const [listWaitStylistServe, setListWaitStylistServe] = useState([])
    const [listWaitSkinnerServe, setListWaitSkinnerServe] = useState([])
    const [salonId, SetSalonId] = useState(0)
    const [text, setText] = useState("")
    const [SMName, setSMName] = useState()
    const [salonName, setSalonName] = useState()
    const [SMPhone, setSMPhone] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // kiểm tra quyền sửa dụng vị trí
    useEffect(() => {
        if ("geolocation" in navigator) {
            getLocation();
        } else {
            setIsModalOpen(true);
            setText("Vui lòng cung cấp vị trí để xem trang web");
        }
    }, []);

    // lấy vị trí của thiết bị
    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                if (position) {
                    getSalonLocation(latitude, longitude);
                    return;
                }
            },
            (error) => {
                console.error("Lỗi:", error.code);
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setText("Vui lòng cấp quyền lấy vị trí cho thiết bị");
                        setIsModalOpen(true);
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setText("Không thể xác định vị trí của thiết bị");
                        setIsModalOpen(true);
                        break;
                    case error.TIMEOUT:
                        setText("Hết thời gian để xác định vị trí");
                        setIsModalOpen(true);
                        break;
                }
            }
        );
    };

    // lấy salonId theo vị trí
    const getSalonLocation = async (latitude, longitude) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GET_LOCATION}/api/bill-wait/salon-nearest`, {
                latitude: latitude,
                longitude: longitude
                // latitude: 20.98352,
                // longitude: 105.79099
            });

            if (response) {
                SetSalonId(response.data.salonId)
                setSalonName(response.data.salonName)
                return
            } else {
                SetSalonId(0)
                return
            }
        } catch (error) {
            setText("Vị trí không chính xác không tìm được Salon");
            setIsModalOpen(true);
            console.error(error);
        }
    };

    //Lấy danh sách khách hàng tại salon
    const getListCus = async (salonName, salonId) => {
        if (salonId === 0) return
        try {
            console.log('push event', salonName.replaceAll(" ", "_"))
            ReactGA.event({
                category: "salon_view",
                action: salonName.replaceAll(" ", "_"),
                label: "salonview",
                value: salonId,
            });

            const response: any = await axios.post(`${process.env.REACT_APP_WEBVIEW_WAITLIST}/api/v1/billWaiting?salonId=${salonId}`, {
                body: {
                    "deviceId": "",
                    "deviceToken": ""
                }
            })
            if (response) {
                setListWaitSupporter(response?.data?.waitSupporter)
                setListWaitStylistServe(response?.data?.waitStylist)
                setListWaitSkinnerServe(response?.data?.waitSkinner)
            } else {
                setListWaitSupporter([])
                setListWaitStylistServe([])
                setListWaitSkinnerServe([])
            }
        } catch (error) {
        }
    };

    // Lấy thông tin sm
    const getUrl = async (salonId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SALON_INFO}/api/AppTv/Get`, {
                params: {
                    salonId: salonId,
                },
            });
            if (response) {
                setSMPhone(response?.data.phone)
                setSMName(response?.data.fullname)
            }
        } catch (error) {
        }
    };

    // lấy thông tin theo salonId
    useEffect(() => {
        if (salonId) {
            getUrl(salonId)
            getListCus(salonName, salonId);
            // const interval = setInterval(() => {
            //     getListCus(salonName, salonId);
            //     }, 60000);
            //     return () => {
            //       clearInterval(interval); 
            //     };
        }
    }, [salonId])

    return (
        <div className="cover" >
            <div className="bottom">
                <div className="text-container">
                    <p className="scrolling-text">Khi book Stylist hoặc Skinner, các anh có thể sẽ phải đợi lâu hơn. Mọi thắc mắc hoặc góp ý vui lòng liên hệ Quản lý Salon {salonName} {SMName}: {SMPhone}</p>
                </div>
            </div>
            <div className="top">
                <div className={listWaitSupporter?.length >= 1 ? "top-left" : "top-left null"} >
                    <div className="header">
                        <span className="header__text">
                            KHÁCH HÀNG CHỜ TƯ VẤN
                        </span>
                        <div className="header__row"></div>
                    </div>
                    <div className="top" >
                        {listWaitSupporter?.length >= 1 ?
                            <div className="body">
                                <Advise
                                    listCus={listWaitSupporter}
                                />
                            </div>

                            :
                            <div className="body-null">
                                <div className="text" style={{ textAlign: "center", marginLeft: 25, marginRight: 25 }}>
                                    <span>Hiện tại đang không có khách đang chờ tư vấn</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={listWaitSkinnerServe?.length >= 1 ? "top-right" : "top-right null"}>
                    <div className="header">
                        <span className="header__text">
                            KHÁCH HÀNG CHỜ PHỤC VỤ GỘI
                        </span>
                        <div className="header__row"></div>
                    </div>
                    <div className="top" >
                        {listWaitSkinnerServe?.length >= 1 ?
                            <div className="body">
                                <Massaging
                                    listCus={listWaitSkinnerServe}
                                />
                            </div>
                            :
                            <div className="body-null">
                                <div className="text" style={{ textAlign: "center", marginLeft: 25, marginRight: 25 }}>
                                    <span>Hiện tại đang không có khách đang chờ gội</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={listWaitStylistServe?.length >= 1 ? "top-left" : "top-left null"} >
                    <div className="header">
                        <span className="header__text">
                            KHÁCH HÀNG CHỜ PHỤC VỤ CẮT
                        </span>
                        <div className="header__row"></div>
                    </div>
                    <div className="top" >
                        {listWaitStylistServe?.length >= 1 ?
                            <div className="body">
                                <Hairstyling
                                    listCus={listWaitStylistServe}
                                />
                            </div>

                            :
                            <div className="body-null">
                                <div className="text" style={{ textAlign: "center", marginLeft: 25, marginRight: 25 }}>
                                    <span>Hiện tại đang không có khách đang chờ cắt</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal
                title="Thông báo"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                maskClosable={false}
                cancelText="Đóng"
            >
                <p>{text}</p>
            </Modal>
        </div>
    );
}

